import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';

const TagTemplate = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const posts = data.allMarkdownRemark.nodes;

  return (
    <Layout>
      <Seo title={`Posts tagged with "${tag}"`} />
      <h1 className="text-4xl font-bold mb-6">Posts tagged with "{tag}"</h1>
      <div className="grid grid-cols-1 gap-5">
        {posts.map(post => (
          <div
            key={post.id}
            className="bg-white shadow-md rounded-lg overflow-hidden border-2 border-gray-200 transition-all duration-300 hover:shadow-lg hover:border-gray-400"
          >
            <div className="p-6">
              <Link
                to={post.fields.slug}
                className="text-gray-900 hover:text-black"
              >
                <h2 className="text-2xl font-bold mb-2">{post.frontmatter.title}</h2>
              </Link>
              <div className="mb-4">
                <small className="text-gray-600">
                  {post.frontmatter.date}
                </small>
                <div className="mt-2 space-x-2">
                  {post.frontmatter.tags.map(tag => (
                    <Link
                      key={tag}
                      to={`/tags/${tag.toLowerCase().replace(/\s+/g, '-')}`}
                      className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 hover:bg-gray-300"
                    >
                      #{tag}
                    </Link>
                  ))}
                </div>
              </div>
              <p className="text-gray-700 mb-4">{post.excerpt}</p>
              <Link
                to={post.fields.slug}
                className="inline-block bg-gray-800 text-white py-2 px-4 rounded hover:bg-black transition-colors duration-300"
              >
                Read More
              </Link>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default TagTemplate;

export const query = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      nodes {
        id
        excerpt(pruneLength: 160)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          tags
        }
      }
    }
  }
`; 